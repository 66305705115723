import React, { useEffect, useState, useRef }  from 'react';
import Map, {
    Layer,
    Source,
    NavigationControl,
    FullscreenControl,
    ScaleControl,
    GeolocateControl,
    Popup
   } from 'react-map-gl';
import MAP_STYLE from '../../data/style.json'
import { styles } from './styles'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';
import useCoord from '../App/useCoord';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
const MAPBOX_TOKEN = process.env.REACT_APP_NOT_SECRET_CODE3

export default function SelectCoordMap(props) {
    const {coord} = useCoord()
    const defaultCoord = useRef(coord)
  
    const onMapClick = event => {
      // // console.log(event)
      const lng = event?.lngLat.lng
      const lat = event?.lngLat.lat
      if(lng){
        // // console.log(JSON.stringify(event?.lngLat.wrap()))
        props.setCoordinates(lng, lat)
      }
    };
  
   
    return(
      <div style={props.mapStyle}>
          <Map  
            initialViewState={{
              latitude: defaultCoord.current.latitude,
              longitude: defaultCoord.current.longitude,
              zoom: 1
            }}        
            width={props.width}
            height={props.height}
            // onViewportChange={props.setViewport}
            mapboxAccessToken={MAPBOX_TOKEN}
            mapStyle={MAP_STYLE}
            onClick={onMapClick}
            ref={props.mapref}
            
          >
           
          </Map>
      </div>
    );
  }
  

SelectCoordMap.propTypes = {
    mapref: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    mapStyle: PropTypes.object.isRequired,
    setCoordinates: PropTypes.func.isRequired,
}