import React, {useState,useEffect,useRef}  from 'react';
import { Card, ListGroup, Dropdown, ButtonGroup, Button } from "react-bootstrap"
import { Element } from "react-scroll"
import PropTypes from 'prop-types'

export default function FlightItems(props) {
    const [itemlist, setItemlist] = useState([])
    useEffect(() => {
        if(Array.isArray(props.bagData)){
            const items = props.sortItems(props?.bagData, props.sortOrder).map(item => {
                const hasAlias = item.alias && item.alias !== ""
                const name = hasAlias ?  item.alias : item.name
                return (
                    <Dropdown style={{marginBottom: "1em", lineBreak: "anywhere" }} as={ButtonGroup}>
                        <ListGroup.Item
                            action
                            variant={props?.selectedItem?._id === item._id ? "info" : ""}
                            active={props?.selectedItem?._id === item._id}    
                            eventKey={item._id} 
                            key={item._id} 
                            onClick={() => {
                                // console.log(item)
                                props.selectItem(item)
                            }} 
                            >
                            {name}
                        </ListGroup.Item>
                        {/* <Dropdown.Menu variant="dark">
                                <Dropdown.Item disabled={true} eventKey={`ExportCSV-${item._id}`}>
                                    Does nothing
                                </Dropdown.Item>
                                
                        </Dropdown.Menu> */}
                    </Dropdown>
                )
            })
            setItemlist(items)
            // // console.log(items)
        }
    }, [props?.bagData, props.selectedItem, props.sortOrder])
   
    return (
        <div style={{
            display: "flex",
            width: "100%",
          }}>
             <Card 
                bg={"dark"}
                key={"AdminListItems"}
                text={"white"}
                style={{minWidth: "95%", height: "90%", margin: "1em", border: "3px outset #0f5132"}}
                className="mb-3">
                    <Card.Header style={{fontSize: "1.3em", fontWeight: "bold", marginBottom: "1em" }}>Flights</Card.Header>
                    <Card.Body>
                    <Element name="adminViewListItems" className="element" id="containerElement" style={{
                    position: "relative",
                    height: "450px",
                    width: "100%",
                    overflowY: "auto",
                    overflowX: "Hidden",
                    marginBottom: "1.5em",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                    }}>
                        
                        <ListGroup style={{width: "95%", padding: "1em", position: "absolute"}}>
                                {itemlist ? itemlist : "loading..."}
                        </ListGroup>
                    </Element>
                </Card.Body>
                </Card>
        </div>
    )
}


FlightItems.propTypes = {
    selectItem: PropTypes.func.isRequired,
    selectedItem: PropTypes.object.isRequired,
    ctx: PropTypes.object.isRequired,
    bagData: PropTypes.array,
    sortItems: PropTypes.func.isRequired,
    sortOrder: PropTypes.string.isRequired,

  }
