import React, {useState,useEffect,useRef}  from 'react';
import BatteryList from './batteryList';
import { NewBatteryForm } from './newBatteryForm';
import { Card } from 'react-bootstrap';
import BatteryInfoView from './batteryInfoView';


export default function BatteryView({update, setUpdate, customers, drones, batteries, getBatteries}) {
    const [addNew, setAddNew] = useState(false)
    
    const [selectedItem, setSelectedItem] = useState(null)
    const selectedBatteryRef = useRef(selectedItem)
    const batteryStatusList = [
        <option value="Healthy">Healthy</option>,
        <option value="Degraded">Degraded</option>,
        <option value="Faulty">Faulty</option>
      ]
    useEffect(()=>{
        selectedBatteryRef.current = selectedItem
    }, [selectedItem])
    const newSelectedItem = (item) => {
        setAddNew(false)
        setSelectedItem(item)
    }
    useEffect(()=>{
        if(selectedBatteryRef?.current && Array.isArray(batteries)){
            batteries.forEach(battery => {
                if(selectedBatteryRef.current.serialNumber === battery.serialNumber)
                    setSelectedItem(battery)
            })
        }

    }, [batteries])
    const updateAddNew = () => {
        setAddNew((prev) => !prev);
    };
    useEffect(() => {

        if(addNew){
            setSelectedItem(null); 
        }// Reset `selectedItem` when `addNew` is toggled off
    }, [addNew]);
    return (
        <div style={{
            display: 'flex',
            height: "100%",
            width: "100%"
        }}>
            <div style={{ width: "30%", height: "100%" }}>
                <BatteryList 
                    batteries={batteries} 
                    selectItem={newSelectedItem} 
                    addNew={addNew} 
                    setAddNew={updateAddNew} 
                />
            </div>
            <div style={{ width: "65%", height: "100%" }}>
                <Card
                    bg={"dark"}
                    key={"AdminSelectedItem"}
                    text={"white"}
                    style={{ minWidth: "95%", height: "90%", margin: "1em", border: "3px outset #0f5132" }}
                    className="mb-3"
                >
                    <Card.Header 
                        style={{ fontSize: "1.3em", fontWeight: "bold", marginBottom: "1em" }}
                    >
                        {selectedItem ? 
                            `Battery: ${selectedItem.serialNumber}` : 
                            addNew ? 
                            "Add new battery" : 
                            "Info"
                        }
                    </Card.Header>
                    <Card.Body>
                        {addNew ? (
                            <NewBatteryForm
                                setUpdate={setUpdate}
                                update={update}
                                drones={drones}
                                batteryStatusList={batteryStatusList}
                                customers={customers}
                                setAddNew={updateAddNew}
                                getBatteries={getBatteries}
                                selectItem={newSelectedItem}
                            />
                        ) : selectedItem && selectedItem.serialNumber ? (
                            <BatteryInfoView
                                update={update}
                                setUpdate={setUpdate}
                                battery={selectedItem}
                                selectItem={newSelectedItem}
                                batteryStatusList={batteryStatusList}
                                customers={customers}
                                drones={drones}
                            />
                        ) : (
                            <div>Select a battery to view details.</div>
                        )}
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}
