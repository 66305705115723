import React, {useState,useEffect,useRef}  from 'react';
import {
    Popup,
   } from 'react-map-gl';
import axios from "axios";
import { styles } from './styles'
import PotreeAreaInfo from '../PotreeAreaInfo/PotreeAreaInfo'
// Be sure to include styles at some point, probably during your bootstraping
import SideNavBar from '../SideNavBar/SideNavBar';
// import DropZone from '../DropZone/DropZone';
import MapView from '../Map/Map';
import PropTypes from 'prop-types'
import useCoord from '../App/useCoord';
import useToken from '../App/useToken';
import Dropdown from 'react-bootstrap/Dropdown'
var testEnv = process.env.REACT_APP_TEST_ENV

export default function Dashboard(props) {
  const {coord} = useCoord()
  const defaultCoord = useRef(coord)
  const {token, getCustomer} = useToken()

  // const mapRef = useRef(null);
  
  const setNewViewport = (coordinates) => {
    let zoom = props.mapRef.current.getZoom()
      props.mapRef.current.easeTo({
        center: coordinates,
        zoom: zoom < 15 ? 15 : zoom,
        duration: 2000
      });
  }
  const [showStands, setShowStands] = useState(false)
  
  const showStandsRef = useRef(false)
  showStandsRef.current = showStands

  useEffect(() => {
    var longitude = defaultCoord.current.longitude  
    var latitude = defaultCoord.current.latitude
   
    if(props.ctx.showShapeData?.current){
      if(props.ctx.selectedShapeData?.current._id === "") {
        return
      }
      const feature = props.ctx.selectedShapeData?.current.geojson.features[0]
      const type = feature.geometry.type
      // // console.log(feature)
      if(String(type).toLowerCase().includes('polygon')){
        if(String(type).toLowerCase() === 'multipolygon'){
          longitude = feature.geometry.coordinates[0][0][0][0]
          latitude = feature.geometry.coordinates[0][0][0][1]
        } else {
          longitude = feature.geometry.coordinates[0][0][0]
          latitude = feature.geometry.coordinates[0][0][1]
        }
      } else {
        longitude = feature.geometry.coordinates[0]
        latitude = feature.geometry.coordinates[1]
      }    
      // // console.log(longitude)
      // // console.log(latitude)
      setNewViewport([longitude, latitude]) 
    } else {
      if(props.selectedBagfile._id === "") {
        return
      }
      if(!Array.isArray(props.selectedBagfile.longitude)){
      // // console.log({longitude: props.selectedBagfile.longitude, latitude: props.selectedBagfile.latitude})
        longitude = -180.0 < props.selectedBagfile.longitude && props.selectedBagfile.longitude < 180.0 ? props.selectedBagfile.longitude : longitude
        latitude =  -90.0 < props.selectedBagfile.latitude && props.selectedBagfile.latitude < 90.0 ? props.selectedBagfile.latitude : latitude
      }
      setNewViewport([longitude, latitude])
    }
    // // console.log(props.selectedBagfile)
    //// console.log({longitude: longitude, latitude: latitude})
    
  }, [props.selectedBagfile, props.ctx?.selectedShapeData?.current])

  
  const [selectCustomers, setSelectCustomers] = useState([])
  const getCustomers = () => {
    var formData = new FormData();
    formData.append('token', token);
    var config = {
        method: 'post',
        url: "api/get_customers",
        data: formData
    };
    axios(config).then(response => {
       // // console.log(response.data)
       if(Array.isArray(response?.data?.customers)){
        let selectCustomerList = [
          <Dropdown.Item onClick={() => props.setFilterCustomerData('101010')} href="#">DF Dev</Dropdown.Item>
        ]
        response.data.customers.map(item => {
        if(item.customerId !== "101010"){
            selectCustomerList.push(<Dropdown.Item onClick={() => props.setFilterCustomerData(item.customerId)} href="#">{item.name}</Dropdown.Item>)
          }
        })
        selectCustomerList.push(<Dropdown.Item onClick={() => props.setFilterCustomerData('all')} href="#">{'All'}</Dropdown.Item>)
        selectCustomerList.push(<Dropdown.Item onClick={() => props.setFilterCustomerData('exclDev')} href="#">{'All excl. dev'}</Dropdown.Item>)
        setSelectCustomers(selectCustomerList)
      }
    }).catch(error => {
        // console.log('error', error)
    })
  }


  const [allPolygonData, setAllPolygonData] = useState([])
  const [allPointerData, setAllPointerData] = useState([])
  const updateAllPolygonData = (data) => {
    if(data) {
      const updatedPolygonData = []
      data.forEach((item) => {
        updatedPolygonData.push( item.polygon ? [item.polygon] : [
          [
            [
              17.674246723065664,
              59.83920052525815
            ],
            [
              17.67156362062798,
              59.837727023894445
            ],
            [
              17.67724181415744,
              59.83681780967032
            ],
            [
              17.678489768779883,
              59.83894972112063
            ],
            [
              17.676867427770787,
              59.839702127865394
            ],
            [
              17.674246723065664,
              59.83920052525815
            ]
          ]
        ])
      })
      setAllPolygonData(updatedPolygonData)
    }
  }
const updateAllPointerData = (data) => {
  if(data) {
    const updatedPointerData = []
    data.forEach((item) => {
      updatedPointerData.push(item.latitude ? [item.latitude, item.longitude] : [])    
    })
    setAllPointerData(updatedPointerData)
    // // console.log(updatedPointerData)
  }
}

  useEffect(() => {
    if(testEnv === 'false'){
      // getBagfiles()
      props.ctx.getShapeData()
      getCustomers()
      return
    }
  }, [])


  const [popupInfo, setPopupInfo] = useState(null);


  const popup = popupInfo ? (
    <Popup
      tipSize={5}
      anchor="top"
      longitude={popupInfo.longitude}
      latitude={popupInfo.latitude}
      closeOnClick={true}
      dynamicPosition={true}
      onClose={()=>setPopupInfo(null)}
    >
      <PotreeAreaInfo displayName={popupInfo.name}>
        Area info
      </PotreeAreaInfo>
    </Popup>
    ) : null   
  
  return(
    <div style={styles.dashboardStyle}>
      <SideNavBar showFlightPath={props.showFlightPath} toggleShowFlightPath={props.toggleShowFlightPath} filters={props.filters} updateFilters={props.updateFilters} filteredItems={props.filteredItems} setSortOrder={props.setSortOrder} sortItems={props.sortItems} sortOrder={props.sortOrder} setFromDate={props.setFromDate} fromDate={props.fromDate} setToDate={props.setToDate} toDate={props.toDate} filterItems={props.filterItems} showStands={showStandsRef.current} setShowStands={setShowStands} ctx={props.ctx} selectCustomers={selectCustomers} setFilterCustomerData={props.setFilterCustomerData} selectedBagfile={props.selectedBagfile} setSelectedBagfile={props.setSelectedBagfile} bagData={props.bagData} changeDropzoneActive={props.changeDropzoneActive}/>
      <MapView 
        ctx={props.ctx}
        showStands={showStandsRef.current}
        selectedBagfile={props.selectedBagfile} 
        setSelectedBagfile={props.setSelectedBagfile} 
        mapStyle={styles.dashboardStyle} 
        mapref={props.mapRef} 
        hasControlers={true} 
        width="100%" height="100%"  
        pins={allPointerData} 
        showCluster={true}
        geoData={props.bagData}
        updateVisibleItems={props.updateVisibleItems}
      />
    </div>
  );
}

Dashboard.propTypes = {
  selectedBagfile: PropTypes.object.isRequired,
  mapRef: PropTypes.object.isRequired,
  setSelectedBagfile: PropTypes.func.isRequired,
  token: PropTypes.func.isRequired,
  ws: PropTypes.object.isRequired,
  webscktRef: PropTypes.object.isRequired,
  changeDropzoneActive: PropTypes.func.isRequired,
  filterCustomerData: PropTypes.string.isRequired,
  setFilterCustomerData: PropTypes.func.isRequired,
  ctx: PropTypes.object.isRequired,
  bagData: PropTypes.array.isRequired,
  filteredItems: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  sortOrder: PropTypes.string.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  // clearFilter: PropTypes.func.isRequired,
  // toggleSortOrder: PropTypes.func.isRequired,
  filterItems: PropTypes.func.isRequired,
  setFromDate: PropTypes.func.isRequired,
  sortItems: PropTypes.func.isRequired,
  fromDate: PropTypes.string.isRequired,
  setToDate: PropTypes.func.isRequired,
  toDate: PropTypes.string.isRequired,
  updateFilters: PropTypes.func.isRequired,
  updateVisibleItems: PropTypes.func.isRequired,
  toggleShowFlightPath: PropTypes.func.isRequired,
  showFlightPath: PropTypes.bool.isRequired,
  

}