import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios'
import useToken from '../App/useToken'

export default function DownloadGeojsonPopup(props) {
  const [okToDownload, setOkToDownload] = useState(false)
  const [href, setHref] = useState(null)
  const name = props.itemToDownload ? props.itemToDownload.alias : ''
  const item = props.itemToDownload ? props.itemToDownload : null
  
  const {token} = useToken()
  const tryRequire = ()  => {
    
  
    var formData = new FormData();
    formData.append('token', token);
    const url = props.ctx.showShapeData?.current ? "api/get_stand_geojson" : "api/get_flight_geojson"
    // console.log(url)
    var config = {
      method: 'post',
      url: url,
      data: props.ctx.showShapeData?.current ? {'token': token, 'AID': props.itemToDownload.AID} : {'token': token, 'id': props.itemToDownload._id}
  };
    axios(config)
    .then((response)=> {
      // console.log(response)
      // // console.log(response.data.trajectory).464
      
      if(response.data.standtreedata){
        // const json = JSON.stringify(response.data.trajectory, null, 2);
        // // console.log(response.data.trajectory)

        const blob = new Blob([response.data.standtreedata], { type: "application/json" });
        // // console.log(blob)

        setHref(URL.createObjectURL(blob))
        // // console.log("setting download ok")
        setOkToDownload(true)
      } else {
        setHref(null)        
        // // console.log("setting download false")

        setOkToDownload(false)
      }
    }).catch((error)=>{
      // console.log(error)
      setOkToDownload(false)
      setHref('')
    })
  };
  let title = okToDownload ? "Download geojson data of stand" + props.itemToDownload ? props.itemToDownload?.alias : '' : "Geojson does not exist for this stand" 
  useEffect(()=>{
    // console.log(props.downloadGeojsonDialogShow)
    // console.log(props.itemToDownload)
    if(props.downloadGeojsonDialogShow && props.itemToDownload){
      tryRequire()
    }
  }, [props.downloadGeojsonDialogShow])
  return (
    <Modal
      show={props.downloadGeojsonDialogShow}
      onHide={() => {
        setOkToDownload(false)
        props.setDownloadGeojsonDialogShow(false)}}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div style={{backgroundColor: "#212529", color: "#ffffff"}}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button onClick={() => {
          setOkToDownload(false)
          props.setDownloadGeojsonDialogShow(false)}}>Cancel</Button>
          <Button
          disabled={!okToDownload} 
          href={href} 
          download={name+'_stand.geojson'} 
          onClick={() => { 
            props.setDownloadGeojsonDialogShow(false)}}>
              Download
          </Button>
      </Modal.Footer>
      </div>
    </Modal>
  );
}

DownloadGeojsonPopup.propTypes = {
  ctx: PropTypes.object.isRequired,
  itemToDownload: PropTypes.object.isRequired,
  downloadGeojsonDialogShow: PropTypes.object.isRequired,
  setDownloadGeojsonDialogShow: PropTypes.func.isRequired,
  data: PropTypes.string.isRequired
}