import React, {useState,useEffect, useRef}  from 'react';
import ReportSelectionList from './reportSelectionList';
import ReportEditing from './reportEditing';
import { PDFViewer } from '@react-pdf/renderer';
import PropTypes from 'prop-types'
import axios from "axios";
import useToken from '../../App/useToken';
import ReportMap from './reportMap';
import ManagingReport from './managingReport';

const dbhFilter = 0.7
export default function ReportView(props) {
    const {token} = useToken()
    const [reportTitle, setReportTitle] = useState('')
    const reportTitleRef = useRef()
    reportTitleRef.current = reportTitle

    const [selectedBagfiles, setSelectedBagfiles] = useState([])
    const selectedBagFilesRef = useRef()
    selectedBagFilesRef.current = selectedBagfiles
    const [forestData, setForestData] = useState([])
    const forestDataRef = useRef()
    forestDataRef.current = forestData
    const updateSelectedBagfiles = (item) => {
        // // console.log(item)
        let temp = [...selectedBagfiles]
        let fdTemp = [...forestData]
        if(temp.some(i => i._id === item._id)){
            let fdIndex = fdTemp.findIndex(i => i.id === item._id)
            let index = temp.indexOf(item)
            fdIndex > -1 && fdTemp.splice(fdIndex,1)
            index > -1 && temp.splice(index, 1)
            setForestData(fdTemp)
            setSelectedBagfiles(temp)
        } else {
            var formData = new FormData();
            formData.append("token", token);
            formData.append("id", item._id)
            var config = {
                method: "post",
                url: "api/forestdata",
                data: formData
            };
            axios(config)
            .then(response => 
            {    
                    // // // console.log(response.data)
                    const area = item.area ? Number(item.area).toFixed(3) : 0.000
                    const fetchedData = response.data.TreeData
                    if(fetchedData) {
                        const data = fetchedData.reduce(
                            (acc, { DBH, volume, height, ID, x, y }) => ({                       
                                DBH: 0 < DBH && DBH < dbhFilter ? [...acc.DBH, ["DBH", Math.round(Number(DBH.toFixed(3)*100))*0.01]] : [...acc.DBH],
                                volume: 0 < volume && volume < 5 && 0 < DBH && DBH < dbhFilter ? [...acc.volume,["Volume", Math.round(Number(volume.toFixed(3))*100)*0.01]] : [...acc.volume],
                                height: 0 < height && height< 30 && 0 < DBH && DBH < dbhFilter ? [...acc.height, ["Height", Math.round(Number(height.toFixed(3))*100)*0.01]] : [...acc.height],
                                sumDBH: 0 < DBH && DBH < dbhFilter ? acc.sumDBH + DBH : acc.sumDBH + 0,
                                sumVol:  0 < volume && volume < 5 && 0 < DBH && DBH < dbhFilter ? acc.sumVol + volume : acc.sumVol + 0,
                                sumHeight: 0 < height && height< 30 && 0 < DBH && DBH < dbhFilter ? acc.sumHeight + height : acc.sumHeight + 0,
                                discardedTrees: DBH >= dbhFilter ? acc.discardedTrees + 1 : acc.discardedTrees + 0,
                                possibleTrees: DBH > 0 || volume !== -1 ? [...acc.possibleTrees, {"ID": ID,"x": x, "y": y, "DBH": Number(DBH.toFixed(3)), "volume": Number(volume.toFixed(3)), "height": Number(height.toFixed(3))} ] : [...acc.possibleTrees],
                                Full: [...acc.Full, {"ID": ID,"x": x, "y": y, "DBH": Number(DBH.toFixed(3)), "volume": Number(volume.toFixed(3)), "height": Number(height.toFixed(3))} ]
                            }),
                            { Full: [], possibleTrees: [], DBH: [["DBH", "0"]], volume: [["Volume", "0"]], height: [["Height", "0"]], sumDBH: 0, sumVol: 0, sumHeight: 0, area: area, discardedTrees:0 }
                        )
                        fdTemp.push({id: item._id, area: item.area ? item.area : 0, name: item.alias? item.alias : item.name, data: data})
                        // // // console.log(data)
                        setForestData(fdTemp)
                        temp.push(item)
                        setSelectedBagfiles(temp)
                    } else {
                        fdTemp.push({id: item._id, area: item.area ? item.area : 0, name: item.alias? item.alias : item.name, data: null})
                        setForestData(fdTemp)
                        temp.push(item)
                        setSelectedBagfiles(temp)
                    }
                
            })
            .catch(error => {
                // // console.log("error", error)
            })
            
        }
        // // // console.log(temp)
    }
    const mapRef = useRef(null);
    useEffect(() => {
        // // console.log(props.bagData)
    }, [props.bagData])
    useEffect(() => {
        // // console.log(selectedBagfiles)
    }, [selectedBagfiles])
    return (
        <div style={{
            height: "95%",
            width: "95%",
            display: "flex"
          }}>
            <div style={{
            height: "90%",
            width: "20%",
          }}>
                <ReportSelectionList 
                    bagData={props.bagData} 
                    ctx={props.ctx} 
                    setSelectedBagfiles={updateSelectedBagfiles} 
                    selectedBagfiles={selectedBagFilesRef.current}
                    />
            </div>
            <div style={{
            height: "90%",
            width: "35%"
          }}> 
           <div style={{
            height: "50%",
            width: "90%"
          }}>
            <ManagingReport 
                setReportTitle={setReportTitle}
                reportTitle={reportTitleRef.current}
            />
          
            </div>
            <div style={{
            height: "50%",
            width: "90%"
          }}> 
          
           
            <ReportMap mapStyle={{width: "95%", height:"95%"}} 
                        mapref={mapRef}
                        geoData={props.bagData}
                        hasControlers={false} 
                        selectedBagfiles={selectedBagFilesRef.current} 
                        setSelectedBagfiles={updateSelectedBagfiles}
                        width="95%" height="95%" 
                        setPopupInfo={() => null}
                        showCluster={false}
                        pins={null}
                        ctx={props.ctx}>

            </ReportMap>
            </div>     {/* <ReportEditing selectedBagfiles={selectedBagFilesRef.current} forestData={forestDataRef.current}/> */}
            </div>
            <div style={{
            height: "90%",
            width: "35%"
          }}>
                <PDFViewer style={{height: "100%"}}>
                    <ReportEditing 
                        selectedBagfiles={selectedBagFilesRef.current} 
                        forestData={forestDataRef.current}
                        title={reportTitleRef.current}/>
                </PDFViewer>
            </div>

        </div>
    )
}

ReportView.propTypes = {
    bagData: PropTypes.array,
    ctx: PropTypes.object.isRequired
}