import React, {useState} from 'react';
import axios from "axios";
import './Login.css'
import PropTypes from 'prop-types'

export default function Login({ setLoading, setToken, setCoord, setCustomer }) {

    const [loginUsername, setLoginUsername] = useState()
    const [loginPassword, setLoginPassword] = useState()
    const [loginError, setLoginError] = useState("")
    const loginUser = async ({loginUsername, loginPassword}) => {
        var url = "api/authorize"
        
        var result = axios.post(url, {'email': loginUsername, 'password': loginPassword})
        .then(response => 
        {   
            // // console.log(response.data)
            if(response.data.access_token) {
                // // console.log(response.data.access_token)
                return response.data.access_token
            } 
            return false
        })
        .catch(error => {
            // console.log('error', error)
            return false
        })   
        
        return result
    }
    const getCustomer = async (token) => {
        var formData = new FormData();
        formData.append('token', token);
        var config = {
            method: 'post',
            url: "api/get_customer_by_token",
            data: formData
        };
        const res = axios(config)
        .then(response => {
            if(response.data.customer){ 
                // // console.log(response.data.customer)
                return response.data.customer
            }
            return null
        })            
        .catch(error => {
            // console.log('error', error)
            return null
        })
        return res
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        const login = await loginUser({loginUsername, loginPassword})
        
        
        if(login){
            // console.log("login success")
            // // console.log(login)
            const customer = await getCustomer(login)
            // // console.log(customer)
            // // console.log(coord)user
            if(!customer || !customer.latitude) {
                setCoord({longitude: 17.296289304973897, latitude: 62.391147358952594})
            } else {
                setCoord({longitude: customer.longitude, latitude: customer.latitude})
                setCustomer(customer)
                setToken(login)
                setLoading(false)
                setLoginError("")
            }

            
        } else {
            setLoginError("Username or password is wrong, please try again...")
        }
    } 
    return(
        <div id='loginWrapper' className='loginWrapper'>
            
            <div id="loginform">
            <img style={{margin: "1em 1em 0em 1em", paddingBottom: "3em", maxWidth:"90%", borderRadius: "25%", alignSelf: "center"}}  src='Deep_Forestry_white.jpg' alt='deepforestrylogo'></img>
                <div>
                <form class="loginFormWrapper" onSubmit={handleSubmit}>
                    <FormInput description="Username" placeholder="Enter your username" type="text" setValue={setLoginUsername}/>
                    <FormInput description="Password" placeholder="Enter your password" type="password" setValue={setLoginPassword}/>
                    <FormButton loginError={loginError} title="Login"/>
                </form>
                </div>
            </div>
        </div>
    )
}
Login.propTypes = {
    setToken: PropTypes.func.isRequired
  }
const FormHeader = props => (
    <h2 id="headerTitle">{props.title}</h2>
);

const FormButton = props => (
  <div id="button" class="login-row" type="submit">
    <button>{props.title}</button>
    <p style={{color:'red'}}>{props.loginError}</p>
  </div>
);

const FormInput = props => (
  <div class="login-row">
    <label>{props.description}</label>
    <input type={props.type} onChange={e => props.setValue(e.target.value)} placeholder={props.placeholder}/>
  </div>  
);

// import React, { useState } from 'react'
// import axios from "axios";
// import './Login.css'
// import PropTypes from 'prop-types'

// export default function Login({ setToken }) {
//     const [loginUsername, setLoginUsername] = useState()
//     const [loginPassword, setLoginPassword] = useState()
//     const [loginError, setLoginError] = useState("")

//     const loginUser = async ({loginUsername, loginPassword}) => {
//         var url = "https://portal.deepforestry.com/api/authorize"
        
//         var result = axios.post(url, {'email': loginUsername, 'password': loginPassword})
//         .then(response => 
//         {   
//             // console.log(response.data)
//             if(response.data.access_token) {
//                 // console.log(response.data.access_token)
//                 return response.data.access_token
//             } 
//             return false
//         })
//         .catch(error => {
//             // console.log('error', error)
//             return false
//         })   
        
//         return result
//     }

//     const handleSubmit = async (e) => {
//         e.preventDefault()
//         const login = await loginUser({loginUsername, loginPassword})
//         // console.log(login)
//         if(login){
//             // console.log("login success")
//             setToken(login)
//             setLoginError("")
//         } else {
//             setLoginError("Username or password is wrong, please try again...")
//         }
//     } 

//   return(
//     <div className="login-wrapper">
//         <h1>Please Log In</h1>
//         <form onSubmit={handleSubmit}>
//         <label>
//             <p>Username</p>
//             <input type="text" onChange={e => setLoginUsername(e.target.value)} />
//         </label>
//         <label>
//             <p>Password</p>
//             <input type="password" onChange={e => setLoginPassword(e.target.value)}/>
//         </label>
//         <div>
//             <button type="submit">Submit</button>
//             <p style={{color:'red'}}>{loginError}</p>
//         </div>
//         </form>
//     </div>
//   )
// }

// Login.propTypes = {
//     setToken: PropTypes.func.isRequired
//   }