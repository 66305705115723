import React, {useState,useEffect,useRef}  from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types'

import SelectedInfoView from '../../AdminTab/GeneralView/SelectedInfoView/SelectedInfoView';
import DroneItems from './droneItems';
import DroneStatus from './droneStatus';


export default function DroneView(props) {
    const [addNew, setAddNew] = useState(false)
    const [selectedItem, setSelectedItem] = useState({name: ''})
    
    const newSelectedItem = (item) => {
        // // console.log(item);
        setSelectedItem(item); // Update selectedItem state
    };
    const handleNewType = () => {
        setAddNew(true)
    }
    return (
        <div style={{
            display: 'flex',
            height: "100%",
            width: "100%"
          }}>
            <div style= {{width: "30%", height: "100%"}}>
                <DroneItems items={props.items} selectItem={newSelectedItem} type={props.type}/>
                {/* {props.type === 'customer' && <Button onClick={addNew()}>{addNewText}</Button>} */}
            </div>
            <div style={{width: "65%", height: "40%"}}>
                <DroneStatus item={selectedItem} ws={props.ws}/>
                {/* {addNew === 'customer' && <NewCustomerForm/> } */}
            </div>
             {/* List view left, Selection, edit, delete.
                Selected item view right
                Edit, add new bottom
                EditView (instead of Selected view)
             */
             
             }
        </div>
    )
}

DroneView.protoTypes = {
    items: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    ws: PropTypes.instanceOf(WebSocket),
}