import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types'
import InputGroup from 'react-bootstrap/InputGroup';
import axios from "axios";
import useToken from '../../../../App/useToken';



// id: Optional[PydanticObjectId] = Field(None, alias="_id")
// name: str
// hp: str
// customerId: str
// dateAdded: Optional[datetime]
// dateUpdated: Optional[datetime]
// status: Optional[str]

//  "/insert_new_drone"

export function NewDroneForm(props) {
  const {token} = useToken()
  const [validated, setValidated] = useState(false);
  const [selectList, setSelectList] = useState([])
  const [droneName, setDroneName] = useState("")
  const [password, setPassword] = useState("")
  const [droneCustomerId, setDroneCustomerId] = useState("101010")
  const [droneStatus, setDroneStatus] = useState("")

  const formRef = useRef(null)
  const handleSubmitNewDrone = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    } else {
        
        const data = {
            'token': token,
            'drone': {
                'name': 'df-drone-'+droneName,
                'hp': password,
                'customerId': droneCustomerId,
                'status': droneStatus
            }
        }
        var config = {
            method: 'post',
            url: "api/insert_new_drone",
            data: data
        };
        axios(config).then(response => {
            // // console.log(response.data)
        }).catch(error => {
            // // console.log('error', error)
        })
        event.preventDefault();
        event.stopPropagation();
    }
    // // console.log(event)
    setValidated(true);
  };
  
  useEffect(()=> {
    if(Array.isArray(props.items)){
        let selectCustomerList = [<option value="101010">DF Dev</option>]
        props.items.map(item => {
            if(item.customerId !== "101010"){
                selectCustomerList.push(<option value={item.customerId}>{item.name}</option>)
            }
        })
        setSelectList(selectCustomerList)
        // // console.log(selectCustomerList)
        // // console.log(formRef)
    }
  }, [props.items])

  return (
    <div>
        <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmitNewDrone}>
            <Form.Group md="4" controlId="validationCustom01">
            <Form.Label>Drone name</Form.Label>   
                <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">df-drone-</InputGroup.Text>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Drone number"
                        aria-describedby="basic-addon1"
                        defaultValue=""
                        onChange={(e)=> setDroneName(e.target.value)}
                    />
                </InputGroup>
            </Form.Group>
            <Form.Group md="4" controlId="validationCustom02">
            <Form.Label>Password</Form.Label>
            <Form.Control
                required
                type="password"
                placeholder="Password"
                defaultValue=""
                onChange={(e) => setPassword(e.target.value)}
            />
            </Form.Group>
            <Form.Group md="4" controlId="validationCustomDrone">
            <Form.Label>Select customer</Form.Label>
            <InputGroup hasValidation>
                <Form.Select onChange={(e)=> setDroneCustomerId(e.target.value)} defaultValue='101010' aria-label="customer select">
                    {
                        selectList
                    }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                Please select a customer
                </Form.Control.Feedback>
            </InputGroup>
            </Form.Group>
            <Form.Group md="6" controlId="validationCustom03">
            <Form.Label>Status (optional)</Form.Label>
            <Form.Select onChange={(e)=>setDroneStatus(e.target.value)} aria-label="Status select">
                <option value=""></option>
                <option value="Active">Active</option>
                <option value="Home">Home</option>
                <option value="Needs repair">Needs repair</option>
            </Form.Select>
            </Form.Group>
        <Button type="submit">Submit form</Button>
        </Form>
    </div>
  );
}

NewDroneForm.propTypes = {
    items: PropTypes.array.isRequired,
}