import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
// import ModalCoordMap from '../../../../Map/ModalCoordMap';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from "axios";
import useToken from '../../../../App/useToken';
import SelectCoordMap from '../../../../Map/SelectCoordMap';



// id: Optional[PydanticObjectId] = Field(None, alias="_id")
// name: str
// hp: str
// customerId: str
// dateAdded: Optional[datetime]
// dateUpdated: Optional[datetime]
// status: Optional[str]

//  "/insert_new_drone"

export function NewCustomerForm(props) {
  const {token} = useToken()
  const [validated, setValidated] = useState(false);
  const [showCoordMap, setShowCoordMap] = useState(false);
  const showMap = () => {
    setShowCoordMap(true)
  }
  const hideMap = () => {
    setShowCoordMap(true)
  }
  const [selectList, setSelectList] = useState([])
  const [customerName, setCustomerName] = useState("")
  const [customerId, setCustomerId] = useState("")
  const [longitude, setLongitude] = useState()
  const [latitude, setLatitude] = useState()
  const setCoordinates = (lng, lat) => {
    // // console.log("lng:", lng)
    // // console.log("lat:", lat)
    setLongitude(lng)
    setLatitude(lat)
  }
  const mapref = useRef(null)
  const formRef = useRef(null)
  const handleSubmitNewDrone = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    } else {
        
        const data = {
            'token': token,
            'customer': {
                'name': customerName,
                'customerId': customerId,
                'longitude': longitude,
                'latitude': latitude
                
            }
        }
        var config = {
            method: 'post',
            url: "api/insert_new_customer",
            data: data
        };
        axios(config).then(response => {
            // // console.log(response.data)
        }).catch(error => {
            // // console.log('error', error)
        })
        event.preventDefault();
        event.stopPropagation();
    }
    // // console.log(event)
    setValidated(true);
  };

  const modalMap = (
    <Modal
    show={showCoordMap}
    onHide={() => setShowCoordMap(false)}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Click on map to choose coordinates.
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{`Longitude: ${longitude}  Latitude: ${latitude}`}</p>
      <div style={{height:"400px", width:"700px"}}>
      <SelectCoordMap
          mapref={mapref}
          width="100%"
          height="100%"
          mapStyle={{width: "100%", height:"100%"}}
          setCoordinates={setCoordinates}
      />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => setShowCoordMap(false)}>Select</Button>
      <Button onClick={() => setShowCoordMap(false)}>Close</Button>
    </Modal.Footer>
  </Modal>
  )
  
  useEffect(()=> {
    if(Array.isArray(props.items)){
        let selectCustomerList = [<option value="101010">DF Dev</option>]
        props.items.map(item => {
            if(item.customerId !== "101010"){
                selectCustomerList.push(<option value={item.customerId}>{item.name}</option>)
            }
        })
        setSelectList(selectCustomerList)
        // // console.log(selectCustomerList)
        // // console.log(formRef)
    }
  }, [props.items])

  return (
    <div>
        {modalMap}
        <div style={{padding:"3em"}}>
        <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmitNewDrone}>
            <Form.Group md="4" controlId="validationCustom01">
            <Form.Label>Customer name</Form.Label>   
                <InputGroup hasValidation>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Customer name"
                        aria-describedby="basic-addon1"
                        defaultValue=""
                        onChange={(e)=> setCustomerName(e.target.value)}
                    />
                </InputGroup>
            </Form.Group>
            <Form.Group md="4" controlId="validationCustom02">
            <Form.Label>Customer Id</Form.Label>
            <Form.Control
                required
                type="text"
                placeholder="Customer id"
                defaultValue=""
                onChange={(e) => setCustomerId(e.target.value)}
            />
            </Form.Group>
            <Form.Group  md="4" controlId="validationCustomUsername">
            <Form.Label>Coordinates</Form.Label>
            
            <InputGroup style={{display: "flex"}} hasValidation >
                <div>
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control
                                required
                                type="number"
                                placeholder="Longitude"
                                aria-describedby="basic-addon1"
                                defaultValue={longitude}
                               
                            />
                </div>
                <div>
                    <Form.Label>Latitude</Form.Label>
                    <Form.Control
                                required
                                type="number"
                                placeholder="Latitude"
                                aria-describedby="basic-addon1"
                                defaultValue={latitude}
                                
                            />
                </div>
                <Button style={{padding:"1em"}} onClick={() => setShowCoordMap(true)}>
                    Select coords on map
                </Button>
            </InputGroup>
            </Form.Group>
        <Button style={{padding:"2em"}} type="submit">Create customer</Button>
        </Form>
        </div>
    </div>
  );
}

NewCustomerForm.propTypes = {
    items: PropTypes.array.isRequired,
}