import React, { useEffect, useRef, useState, useMemo} from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import axios from "axios";
import Dashboard from '../Dashboard/Dashboard';
import ForestDetails from '../ForestDetails/ForestDetails';
import PotreeViewer from '../PotreeViewer/PotreeViewer';
import PropTypes from 'prop-types'
import './tabs.css'
import {useMediaQuery} from '../../helpers/mediaQuery';
import DropZone from '../DropZone/DropZone';
import useToken from '../App/useToken';
import AdminView from '../AdminTab/AdminView';
import DashboardView from '../DashboardTab/dashboardView';
import { hasAccess } from '../Helper/Helper';
import { FaSort } from 'react-icons/fa';


export default function ControlledTabs(props) {
  const {token, getCustomer} = useToken()
  const [customer, setCustomer] = useState(getCustomer())
  const isRowBased = useMediaQuery('(max-width: 420px)');
  const [key, setKey] = useState('map');
  const [selectedBagfile, setSelectedBagfile] = useState({_id: "", name:""})
  const [selectedFlightPath, setSelectedFlightPath] = useState(null)
  const [prevSelectedBagfile, setPrevSelectedBagfile] = useState("")
  const [filterCustomerData, setFilterCustomerData] = useState()
  const filterCustomerDataRef = useRef()
  filterCustomerDataRef.current = filterCustomerData

  const [filterBagData, setFilterBagData] = useState('')
  const filterBagDataRef = useRef()
  filterBagDataRef.current = filterBagData
  const [filterAID, setFilterAID] = useState('')
  const filterAIDRef = useRef()
  filterAIDRef.current = filterAID
  const [loadDevData, setLoadDevData] = useState(false)
  const bagfile = useRef(selectedBagfile)
  const prevBagfileId = useRef(prevSelectedBagfile)
  useEffect(()=>{
    if(!props.loading){
      if(customer.customerId !== "101010") {
        setFilterCustomerData(customer.customerId)
      } else {
        if(loadDevData){
          setFilterCustomerData(customer.customerId)
        } else {
          setLoadDevData(true)
        }
      }
    }
  }, [props.loading])
  const getFlightPath = (selectedItem) => {

    var formData = new FormData();
    formData.append('token', token);
    formData.append('name', selectedItem.name);
    formData.append('id', selectedItem._id);
    formData.append('DOF', selectedItem.DOF);
    formData.append('droneId', selectedItem.droneId);
    formData.append('customerId', selectedItem.customerId);
    var config = {
      method: 'post',
      url: "file-handler/get_trajectory_geojson",
      data: formData
    };
    if(!showShapeDataRef?.current){
      axios(config)
      .then((response)=> {
        // console.log(response)
        // // console.log(response.data.trajectory)
        if(response.data && response.data.trajectory){
          // const json = JSON.stringify(response.data.trajectory, null, 2);
          // // console.log(response.data.trajectory)
          const parsedFlightPath= JSON.parse(response.data.trajectory);
          setSelectedFlightPath(parsedFlightPath); 
        }
      }).catch((error)=>{
        // console.log(error)

      })
    } else {
      setSelectedFlightPath(null)
    }
  } 
  useEffect(() =>{
    
    // console.log(showFlightPath)
    if(showFlightPath && selectedBagfile._id !== ""){
      getFlightPath(selectedBagfile)
      // console.log("here")
    }
  },[showFlightPath, selectedBagfile])
  
  useEffect(()=>{
    if(mapViewRef?.current){
      const flightPathSource = mapViewRef.current.getSource('flightPath-source');
      if(!showFlightPath){
        flightPathSource.setData({
          type: 'FeatureCollection',
          features: []
        })
      }else {
        flightPathSource.setData(selectedFlightPath)
      }
    }
    if(forestDetailMapRef?.current){
        const flightPathSource = forestDetailMapRef.current.getSource('flightPath-source');
        if(!showFlightPath){
          flightPathSource.setData({
            type: 'FeatureCollection',
            features: []
          })
        }else {
          flightPathSource.setData(selectedFlightPath)
        }
    }
  },[selectedFlightPath])
  useEffect(() =>{
    // prevBagfileId.current = bagfile.current
    // setPrevSelectedBagfile(bagfile.current)
    bagfile.current = selectedBagfile 
  },[selectedBagfile])

  const setNewSelectedBagFile = (newBagfile) => {
    prevBagfileId.current = selectedBagfile._id ? selectedBagfile._id : ""
    setPrevSelectedBagfile(prevBagfileId.current)
    setSelectedBagfile(newBagfile)
    setRenderPotree(false)
    setSelectedFlightPath(null)
  }
  const [renderPotree, setRenderPotree] = useState(false)
  const [showShapeData, setShowShapeData] = useState(false)
  const [showFlightPath, setShowFlightPath] = useState(false)
  const showFlightPathRef = useRef(showFlightPath)
  const toggleShowFlightPath = () => {
    setShowFlightPath((prev) => {
        // console.log("Previous value:", prev);
        if(!prev && selectedBagfile._id !== ""){
          getFlightPath(selectedBagfile)
        } else {
          // console.log("Here?")

          setSelectedFlightPath(null)

        }
        return !prev;
    });
  };
  useEffect(() => {
    // console.log("Parent State Changed: showFlightPath =", showFlightPath);
  }, [showFlightPath]);
  const showShapeDataRef = useRef(false)
  showShapeDataRef.current = showShapeData
  const [shapeData, setShapeData] = useState(null)
  const shapeDataRef = useRef(null)
  shapeDataRef.current = shapeData
  const [drones, setDrones] = useState([])
  const dronesRef = useRef([])
  dronesRef.current = drones
  const [forestdata, setForestdata] = useState({
    possibleTrees: 0,  
    area: 0, 
    basalArea: 0,
  })
  const forestdataRef = useRef({
    possibleTrees: 0,  
    area: 0, 
    basalArea: 0,
  })
  forestdataRef.current = forestdata
  const [selectedShapeData, setSelectedShapeData] = useState({_id: "", AID: ""})
  const selectedShapeDataRef = useRef({_id: "", AID: ""})
  selectedShapeDataRef.current = selectedShapeData
  // const getShapeData = () => {
  //   const data = {
  //     'token': token,
  //     'customerId': customer
  //   }

  //   // var formData = new FormData();
  //   // var token = sessionStorage.getItem('token')
  //   // formData.append('token', token);
  //   var config = {
  //       method: 'post',
  //       url: "api/get_shapedata",
  //       data: data
  //   };
  //   axios(config)
  //       .then(response => 
  //       {
          
  //         const ResShapedata = response.data.shapedata
  //         if(Array.isArray(ResShapedata)){
  //           setShapeData(ResShapedata)
  //         }
  //       })
  //       .catch(error => {
  //         // console.log("error", error)
  //     })
  // }
  const [surveyArea, setSurveyArea] = useState(null)
  const surveyAreaRef = useRef([])
  surveyAreaRef.current = surveyArea
  const getSurveyAreas = async () => {
    const data = {
      'token': token,
      'customerId': filterCustomerDataRef.current
    }
    var config = {
      method: 'post',
      url: "api/get_filtered_surveyarea",
      data: data
  };
  axios(config)
    .then(response => {
        const fetchedData = response.data.surveyAreas
        let pindata = []
        // console.log(fetchedData)
        if(Array.isArray(fetchedData)) {
          
          setSurveyArea(fetchedData);
          
        } 
      })            
      .catch(error => {
        // console.log('error', error)
      })
  }
  const getCustomerDrones = () => {
    var formData = new FormData()
    formData.append('token', token)
    var config = {
      method: 'post',
      url: "api/get_filtered_drones",
      data: formData
  };
  axios(config)
    .then(response => {
      setDrones(response.data.drones)
      // console.log(response.data.drones)

    })
  }
  const ctx = useMemo(
    () => ({
      filterBagData: filterBagDataRef.current,
      filterAID: filterAIDRef.current,
      filterCustomerData : filterCustomerDataRef.current,
      setFilterAID: (filter) => {
        // // console.log(filter)
        setFilterAID(filter)},
      setFilterBagData: (filter) => {
        // // console.log(filter)
        setFilterBagData(filter)},
      bagData: bagDataRef,
      websckt: webscktRef,
      surveyArea: surveyAreaRef,
      selectedShapeData: selectedShapeDataRef,
      selectShapeData: (item) => {
        setSelectedShapeData(item)
      },
      forestdata: forestdataRef.current,
      setForestdata: ({possibleTrees, area, basalArea}) => {
        setForestdata({
          possibleTrees: possibleTrees,
          area: area, 
          basalArea: basalArea})
      },
      drones: dronesRef.current,
      prevSelectedBagfileId: prevBagfileId.current,
      shapeData: shapeDataRef,
      getShapeData: getSurveyAreas,
      showShapeData: showShapeDataRef,
      toggleShowShapeData: () => {
        if(!surveyAreaRef.current){
          getSurveyAreas()
        }
        if(mapViewRef?.current){
          const map = mapViewRef.current.getMap()
          if (map.getLayer("flightPath-layer")) { 
            const visibility = !showShapeDataRef.current ? "none" : "visible";
            map.setLayoutProperty("flightPath-layer", "visibility", visibility);
            
          }
        }
        if(forestDetailMapRef?.current){
          const map = forestDetailMapRef.current.getMap()

          if (map.getLayer("flightPath-layer")) { 
            const visibility = !showShapeDataRef.current ? "none" : "visible";
            map.setLayoutProperty("flightPath-layer", "visibility", visibility);
            
          }
        }
        setShowShapeData(!showShapeDataRef.current)
      }
    }),
    [drones, shapeData, prevSelectedBagfile, selectedBagfile, websckt,filterCustomerData, bagData, filterBagData, filterAID, surveyArea, showShapeData, forestdata]
  );

  const [bagData, setBagData] = useState(null)
  const bagDataRef = useRef([])
  bagDataRef.current = bagData

  

  const getBagfiles = async () => {
    const data = {
      'token': token,
      'customerId': filterCustomerDataRef.current,
      'filterAID': filterAIDRef.current
    }
    var config = {
        method: 'post',
        url: "api/get_filtered_dronefiles",
        data: data
    };
    axios(config)
      .then(response => {
        const fetchedData = response.data.dronefiles
        let pindata = []
        if(Array.isArray(fetchedData)) {
          
          setBagData(fetchedData);

          
        } 
      })            
      .catch(error => {
        // console.log('error', error)
      })
  }

  
  useEffect(() => {
      if(filterCustomerData){
        getCustomerDrones()
        getBagfiles()
      }
      return
  }, [filterCustomerData, filterBagData, filterAID])

  useEffect(() => {
    if(filterCustomerData){
      getSurveyAreas()
    }
    return
  }, [filterCustomerData])

  const [websckt, setWebsckt] = useState(null);
  const url = "wss://portal.deepforestry.com/api/ws/"+token;
  const webscktRef = useRef(null)
  webscktRef.current = websckt
  useEffect(() => {
    const ws = new WebSocket(url)
    webscktRef.current = ws
    setWebsckt(ws);
    ws.onopen = (event) => {
      // // console.log(event)
      // console.log('Connecting')
      ws.send("Connect");
    };
    
    //clean up function when we close pagew
    return () => { 
      ws.close();
      setWebsckt(ws)
      // console.log("Closing socket...")
    }
  },[]);
  useEffect(() => {
    if(!webscktRef.current){ return }
    webscktRef.current.onmessage = (msg) => {
      const data = JSON.parse(msg.data);
      if(data.type === "dronefileAdded" || data.type === "dronefileDeleted"){     
        getBagfiles()
      }
      else if(data.type === "dronefileUpdated"){       
        getBagfiles()
      }
      else if(data.type === "surveyAreaAdded"){
        getSurveyAreas()
      }
      else if(data.type === "surveyAreaUpdated"){
        getSurveyAreas()
      }
      else if(data.type === "droneUpdated"){
        getCustomerDrones()
      }

    }
  },[webscktRef.current])
  const [dropzoneActive, setDropzoneActive] = useState(false)
  const changeDropzoneActive = (active) => {
    setDropzoneActive(active)
  }
  
  const dropZone = ( 
    <DropZone
      show={dropzoneActive}
      onHide={changeDropzoneActive}
      token={token}
    />
    )
  const [sortOrder, setSortOrder] = useState("desc");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filters, setFilters] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

const extractFullDateFromName = (name) => {
    const match = name?.match(/(\d{4})-(\d{2})-(\d{2})-(\d{2})-(\d{2})-(\d{2})/);
    
    if (!match) return null; // No valid date found

    // Correctly extract matched groups
    const year = parseInt(match[1], 10);
    const month = parseInt(match[2], 10) - 1; // JS Date months are 0-based
    const day = parseInt(match[3], 10);
    const hours = parseInt(match[4], 10);
    const minutes = parseInt(match[5], 10);
    const seconds = parseInt(match[6], 10);

    return new Date(year, month, day, hours, minutes, seconds);
}

  const extractDateFromName = (name) => {
      const match = name.match(/\d{4}-\d{2}-\d{2}/); // Extract date in YYYY-MM-DD format
      return match ? match[0] : null;
    };
  
  const mapViewRef = useRef(null);
  const forestDetailMapRef = useRef(null);

  const updateFilters = (newFilter) => {
    if(newFilter === "clear"){
      setFilters([])
      setFromDate("")
      setToDate("")
      return
    } else {
      setFilters((prevFilters) =>
        prevFilters.includes(newFilter)
          ? prevFilters.filter((f) => f !== newFilter) // Remove if exists
          : [...prevFilters, newFilter] // Add if not exists
      );
    }
  };
  const getItemsInView = (mapRef, items) => {
    const bounds = mapRef.current.getMap().getBounds();
    const visibleItems = items.filter((item) => {
      const { longitude, latitude } = item;
      return (
        longitude >= bounds.getWest() &&
        longitude <= bounds.getEast() &&
        latitude >= bounds.getSouth() &&
        latitude <= bounds.getNorth()
      );
    })
    return visibleItems
  }
  const updateVisibleItems = () => {
    if(bagData){
      if(key === 'map') {
        if (!mapViewRef.current) return;
        const visibleItems = getItemsInView(mapViewRef, bagData)
        setVisibleItems(visibleItems)
      } else if(key === 'forestdetails'){
        if (!forestDetailMapRef.current) return;
        const visibleItems = getItemsInView(forestDetailMapRef, bagData)
        setVisibleItems(visibleItems)
      }
    }
  }
  const _filterItems = (items) => {
    if(filters.length < 1 && (!fromDate && !toDate)){
      return items
    }
    // console.log(filters)
    return items.filter((item) => {
      if(filters.length > 0) {
        if(filters.includes('reviewed') && filters.includes('notReviewed')){}
        else if(filters.includes('reviewed') && !item.isReviewed) { return false }
        else if(filters.includes('notReviewed') && item.isReviewed) { return false }
        if(filters.includes('inStand') && filters.includes('notInStand')){}
        else if(filters.includes('inStand') && !item.AID) { return false }
        else if(filters.includes('notInStand') && item.AID) { return false }
      }
      if(fromDate && toDate) {
        const name = item.DOF
        const itemDate = extractDateFromName(name);
        if (!itemDate) return false;
  
        const itemDateObj = new Date(itemDate);
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);
        // // console.log(itemDateObj >= fromDateObj && itemDateObj <= toDateObj)
        return itemDateObj >= fromDateObj && itemDateObj <= toDateObj;
      } else {
        return true
      }
      
    });
  }
  const applyFilterItems = () => {
        if(visibleItems && visibleItems.length > 0 && filters.includes('inView')){
          // console.log("visible items")
          const filtered = _filterItems(visibleItems)
          setFilteredItems(filtered)
          
        } else if(bagData){
          // console.log("flitering bagdata")
          const filtered = _filterItems(bagData)
          setFilteredItems(filtered)
        }
          // createDronefileList(sortItems(filtered, sortOrder))
    };
  const sortItems = (items, order) => {
    if(items) return [...items].sort((a, b) => {
          const aName = a.DOF
          const bName = b.DOF
          // // console.log(extractFullDateFromName(aName))
          // // console.log(new Date(extractFullDateFromName(aName)))
          const dateA = extractFullDateFromName(aName);
          const dateB = extractFullDateFromName(bName);
          // // console.log( order === "asc" ? dateA - dateB : dateB - dateA)
          return order === "asc" ? dateA - dateB : dateB - dateA;
      });
  };
  useEffect(()=>{
    // console.log("apply filter")
      applyFilterItems()
  }, [filters, visibleItems, bagData])


  // const toggleSortOrder = () => {
  //     const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
  //     setSortOrder(newSortOrder);
  //     if((!fromDate || !toDate) && filteredItems.length<1){
  //         // console.log("not filtered")
          
  //         createDronefileList(sortItems(bagData, newSortOrder));
  //     } else {
  //         createDronefileList(sortItems(filteredItems, newSortOrder));
  //     }
  // };
  // const clearFilter = () => {
  //     setFromDate("");
  //     setToDate("");
  //     setFilteredItems([]); // Show all items
  //     setSortOrder("asc");
  //     createDronefileList(sortItems(bagData, "asc"));
  //     Reset sort order to default
  //   };
  useEffect(() => {
    if(key === 'map' && mapViewRef.current){
      setTimeout(() => {
        mapViewRef.current.getMap().resize();
      }, 300);
    }
    if(key === 'forestdetails' && forestDetailMapRef.current){
      setTimeout(() => {
        forestDetailMapRef.current.getMap().resize();
      }, 300);
    }
  }, [key]);

  return (
      <Tabs
        id="controlled-tab"
        activeKey={key}
        onSelect={(k) => {
          setKey(k)
          if(k === '3dviewer'){
            setRenderPotree(true)
          }
          // if(k === 'map' && mapViewRef.current){
          //   mapViewRef.current.getMap().resize();
          // }
          // if(k === 'forestdetails' && forestDetailMapRef.current){
          //   forestDetailMapRef.current.getMap().resize();
          // }
        }}
        className="my-tabs"
        transition={false}
        style={isRowBased ? {paddingLeft: "0", display: "flex", flexDirection: "column"} : { paddingLeft: "1.5em", flexDirection: "row"}}
      >
        <Tab style={{height: "100%", width:"100%"}} eventKey="map" title="Map view" >
            <Dashboard showFlightPath={showFlightPath} toggleShowFlightPath={toggleShowFlightPath} mapRef={mapViewRef} updateVisibleItems={updateVisibleItems} filters={filters} bagData={bagData} updateFilters={updateFilters} setSortOrder={setSortOrder} sortItems={sortItems} setFromDate={setFromDate} fromDate={fromDate} setToDate={setToDate} toDate={toDate} filteredItems={filteredItems} sortOrder={sortOrder} filterItems={applyFilterItems} websctRef={webscktRef} ctx={ctx} filterCustomerData={filterCustomerData} setFilterCustomerData={setFilterCustomerData} selectedBagfile={selectedBagfile} setSelectedBagfile={setNewSelectedBagFile} token={props.token} ws={webscktRef.current} changeDropzoneActive={changeDropzoneActive}/>
            {dropZone}
        </Tab>
        <Tab style={{height: "100%"}} eventKey="forestdetails" title="Forest Details">
          <ForestDetails updateVisibleItems={updateVisibleItems} forestDetailMapRef={forestDetailMapRef} bagData={bagData} filters={filters} updateFilters={updateFilters} setSortOrder={setSortOrder} sortItems={sortItems} setFromDate={setFromDate} fromDate={fromDate} setToDate={setToDate} toDate={toDate} filteredItems={filteredItems} sortOrder={sortOrder} filterItems={applyFilterItems} ctx={ctx} filterCustomerData={filterCustomerData} setFilterCustomerData={setFilterCustomerData} selectedBagfile={selectedBagfile} setSelectedBagfile={setNewSelectedBagFile} token={props.token} ws={webscktRef.current}/>
        </Tab>
        <Tab style={{height: "100%"}} eventKey="3dviewer" title="3D Viewer" >
          <PotreeViewer selectedBagfile={selectedBagfile} setSelectedBagfile={setNewSelectedBagFile} renderPotree={renderPotree}/>
        </Tab>
        {customer && hasAccess(customer) ? (<Tab style={{height: "100%"}} eventKey="dashboard" title="Dashboard" >
          <DashboardView filteredItems={filteredItems} sortItems={sortItems} sortOrder={sortOrder} ws={websckt} bagData={bagData} selectedBagfile={selectedBagfile} setSelectedBagfile={setNewSelectedBagFile}/>
        </Tab>) : null}
        {customer && hasAccess(customer) ? 
        (<Tab style={{height: "100%", width:"100%"}} eventKey="missionview" title="Admin" >
          <AdminView bagData={bagData}/>
        </Tab>) : null}
      </Tabs>
  );
}

ControlledTabs.propTypes = {
  token: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  }