import React, { useState, useEffect, useRef } from 'react';
import html2pdf from 'html2pdf.js'
import axios from "axios";
import { Button } from 'react-bootstrap';
import FlightItems from './flightItems';

const FlightReport = ({ctx, selectedBagfile, showFlights, filteredItems, sortItems, sortOrder}) => {
  const iframeRef = useRef(null);
  const [htmlContent, setHtmlContent] = useState('');
  const [selectedFlight, setSelectedFlight] = useState(null);

  const getFlightReport = async (selectedBagfile) => {
    if(selectedBagfile) {
      var formData = new FormData();
      var token = sessionStorage.getItem("token")
      formData.append("token", token);
      formData.append("name", selectedBagfile.name);
      formData.append("DOF", selectedBagfile.DOF);
      formData.append("droneId", selectedBagfile.droneId);
      formData.append("customerId", selectedBagfile.customerId);
      var config = {
          method: "post",
          url: "file-handler/getFlightReport",
          data: formData
      };
      axios(config) // Replace with your actual API endpoint
        .then(response => {
          // console.log(response)
          setHtmlContent(response.data)})
        .catch(error => {
          setHtmlContent('<h1>HTML file not found</h1>');
          console.error('Error fetching HTML:', error)}
        );
  }
  };
  // Fetch the HTML content from the backend
  useEffect(() => {
    // // console.log(selectedBagfile)
    // getFlightReport(selectedBagfile)
    setSelectedFlight(selectedBagfile)
    // checkFileExists(selectedBagfile)
  }, [selectedBagfile]);

  useEffect(() => {
    // console.log(selectedFlight)
    if(selectedFlight){
      getFlightReport(selectedFlight)
    }
    // setSelectedFlight(selectedBagfile)
    // checkFileExists(selectedBagfile)
  }, [selectedFlight]);

  
  // Inject the HTML content into the iframe
  useEffect(() => {
    if (iframeRef.current && htmlContent) {
      const iframeDoc = iframeRef.current.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(htmlContent);  // Write the fetched HTML content into the iframe
      iframeDoc.close();
    }
  }, [htmlContent]);

  return (
    <div style={{width: "100%", display: "flex"}}>
     {showFlights && <div style={{width: "30%", display: "flex"}}>
        <FlightItems sortItems={sortItems} sortOrder={sortOrder} selectedItem={selectedFlight} selectItem={setSelectedFlight} ctx={ctx} bagData={filteredItems}/>
      </div> }
      <div style={{width: showFlights ? "70%" : "90%", display: "flex", alignItems: "center", padding: "1em"}}>
        <iframe
          ref={iframeRef}
          title="Fetched HTML"
          style={{ width: '100%', height: '95%', border: "#70b854 solid 3px", backgroundColor: "antiquewhite", padding: "1em"}}
        ></iframe>
      </div>
    </div>
  );
  // return (
  //   <div>
  //     {/* Render the fetched HTML */}
  //     <h1>Local HTML Viewer</h1>
  //     <div
  //           id="html-content"
  //           dangerouslySetInnerHTML={{ __html: htmlContent }}
  //     ></div>

   
  //     {/* <iframe 
  //       // src={htmlContent} 
  //       style={{ width: '100%', height: '80%', border: '1' }}
  //       title="Local HTML" */}
  //     {/* ></iframe> */}
  //     {/* Button to export as PDF */}
  //     {/* <Button disabled={!selectedBagfile} onClick={exportAsPdf}>Export as PDF</Button> */}
  //   </div>
  // );
};

export default FlightReport;
