import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import axios from "axios";
import useToken from '../App/useToken';
import { saveAs } from 'file-saver';

export default function DownloadGeoDialogPopup(props) {
  const {token} = useToken()
  const name = props.itemToDownload ? props.itemToDownload.name : ''
  const [progress, setProgress] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState(null);
  const [cancelController, setCancelController] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  // const item = props.itemToDownload ? props.itemToDownload : null
  // const path = item ? 
  // 'https://portal.deepforestry.com/potree/app-mounted-file-storage/customers/'+item.customerId+'/'+item.droneId+'/'+item.DOF+'/pcd_files/'+item.name+'.pcd' :
  // ''
  useEffect(()=>{
    setErrorMessage('');
  },[props.downloadGeoDialogShow])

  const downloadFile = async (item) => {
    if(item){
      const formData = new FormData();
      let url = "file-handler/downloadGeoreferencedData"
      let responseType = 'blob'
      // console.log()
    
      formData.append('name', item.name);
      formData.append('DOF', item.DOF);
      formData.append('droneId', item.droneId);
      formData.append('customerId', item.customerId);
      formData.append('id', item._id);
      formData.append('token', token);
    
      const controller = new AbortController();
      setCancelController(controller);
      const config = {
        signal: controller.signal,
        method: 'post',
        url: url,
        data: formData,
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          // console.log(progressEvent)
          const { loaded, total } = progressEvent;
          setProgress(Math.round((loaded / total) * 100));
          
          const elapsedTime = (Date.now() - startTime) / 1000; // Time in seconds
          const downloadSpeed = loaded / elapsedTime; // Bytes per second
          const timeRemaining = (total - loaded) / downloadSpeed; // Time in seconds
          setEstimatedTime(timeRemaining);
        }
      }
      const startTime = Date.now();
    
      axios(config).then(response => {
        
        let filename = item.name+".laz"
        
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        saveAs(blob, filename);
        setProgress(0);
        setEstimatedTime(null);
        setErrorMessage('');
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          // console.log('Download canceled');
        } else if (error.response && error.response.status === 404) {
          setErrorMessage('File not found');
        } else {
          setErrorMessage('An error occurred while downloading the file')
          // console.error('There was an error!', error);
        }
      })
      .finally(() => {
        setCancelController(null);
      });
    
    }
  }
  const cancelDownload = () => {
    if (cancelController) {
      cancelController.abort('Download canceled by user');
      setProgress(0);
      setEstimatedTime(null);
      // console.log("canceled download")
    }
    props.setItemToDownload()
    props.setDownloadGeoDialogShow(false)
  };
  return (
    <Modal
      show={props.downloadGeoDialogShow}
      onHide={() => {
        props.setItemToDownload()
        props.setDownloadGeoDialogShow(false)
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div style={{backgroundColor: "#212529", color: "#ffffff"}}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          
          Download item {props.itemToDownload ? props.itemToDownload.name : ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {progress > 0 && (
        <div>
          <p>Download progress: {progress}%</p>
          <p>Estimated time remaining: {Math.round(estimatedTime)} seconds</p>
        </div>
      )}
      {errorMessage && (
        <div style={{ color: 'red' }}>
          <p>{errorMessage}</p>
        </div>
      )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={cancelDownload} >Cancel</Button>
          <Button
            disabled={cancelController}
            onClick={() => { 
              downloadFile(props.itemToDownload)
            }}>
              Download
          </Button>
      </Modal.Footer>
      </div>
    </Modal>
  );
}

DownloadGeoDialogPopup.propTypes = {
  itemToDownload: PropTypes.object.isRequired,
  downloadGeoDialogShow: PropTypes.bool.isRequired,
  setDownloadGeoDialogShow: PropTypes.func.isRequired,
  setItemToDownload: PropTypes.func.isRequired,
  ctx: PropTypes.object.isRequired,
}