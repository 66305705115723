import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  section: {
    margin: 5,
    padding: 10,
    debug: true
  },
  summary: {
    margin: 15,
    fontFamily: 'Times-Roman',
    debug: true
  },
  summaryTitle: {
    margin: 10,
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Times-Roman',
    debug: true
  },
  summary: {
    margin: 10,
    marginLeft: 30,
    fontSize: 14,
    fontFamily: 'Times-Roman'
  },
  image: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    margin: 10,
    height: 50,
    
  },
   title: {
    fontSize: 20,
    margin: 10,
    textAlign: 'center',
    fontFamily: 'Times-Roman'
  },
});

// Create Document Component
export default function ReportEditing(props) {
    const [report, setReport] = useState([])
    useEffect(() => {

        // // console.log(props.forestData)
        if(Array.isArray(props.forestData)){
            
            let totAvgDBH = 0
            let totTotTrees = 0
            let totAvgHeight = 0
            let totAvgTreeDensity = 0
            let totAvgVol = 0
            let totArea = 0

            let r = []
            r = props.forestData.map(i => {
              let forestData = i
              var possibleTrees = forestData.data.possibleTrees.length
              var lengthDBH = forestData.data.DBH.length - 1 
              var lengthVol = forestData.data.volume.length - 1
              var lengthHeight = forestData.data.height.length - 1
              
              var avgDBH = Number(forestData.data.sumDBH.toFixed(3)/lengthDBH).toFixed(3) 
              var avgVol = Number(forestData.data.sumVol.toFixed(3)/lengthVol).toFixed(3) 
              var avgHeight = Number(forestData.data.sumHeight.toFixed(3)/lengthHeight).toFixed(3)
              var totTrees = Number(possibleTrees)
              var area = forestData.area ? Number(forestData.area).toFixed(3) : 0.000
              var treeDensity = area !== 0.000 ? Number(totTrees/Number(area)).toFixed(3) : 0.000 
              totAvgDBH += Number(avgDBH)
              totAvgHeight += Number(avgHeight)
              totAvgVol += Number(avgVol)
              totTotTrees += Number(totTrees)
              totAvgTreeDensity += Number(treeDensity)
              totArea += Number(area)
              return (
              <View style={styles.summary}>
                  <Text style={styles.summaryTitle} >{i.name}</Text>
                  <Text style={styles.summary} >Total Number of Trees: {totTrees}</Text>
                  <Text style={styles.summary} >Area (hectare): {area}</Text>
                  <Text style={styles.summary} >Tree Density (trees per hectare): {treeDensity}</Text>
                  <Text style={styles.summary} >Avarage Volume (m²): {avgVol} </Text>
                  <Text style={styles.summary} >Avarage DBH (m): {avgDBH}</Text>
                  <Text style={styles.summary} >Avarage Height (m): {avgHeight} </Text>
              </View>)
            })
            // // console.log(props.forestData.length)
            // // console.log(totAvgDBH)
            // // console.log(totAvgHeight)
            // // console.log(totAvgHeight)
            // // console.log(totAvgVol)
            // // console.log(totAvgTreeDensity)
            totAvgDBH = Number(totAvgDBH/props.forestData.length).toFixed(3)
            totAvgHeight = Number(totAvgHeight/props.forestData.length).toFixed(3)
            totAvgVol = Number(totAvgVol/props.forestData.length).toFixed(3)
            totAvgTreeDensity = Number(totAvgTreeDensity/props.forestData.length).toFixed(3)
            r.push((
              <View style={styles.summary}>
                  <Text style={styles.summaryTitle} >Total summary</Text>
                  <Text style={styles.summary} >Total Number of Trees: {totTotTrees}</Text>
                  <Text style={styles.summary} >Area (hectare): {totArea}</Text>
                  <Text style={styles.summary} >Tree Density (trees per hectare): {totAvgTreeDensity}</Text>
                  <Text style={styles.summary} >Avarage Volume (m²): {totAvgVol} </Text>
                  <Text style={styles.summary} >Avarage DBH (m): {totAvgDBH}</Text>
                  <Text style={styles.summary} >Avarage Height (m): {totAvgHeight} </Text>
              </View>))
            setReport(r)
        }
        // // console.log(report)
    },[props.forestData])
    return (
        <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
              <Text style={styles.title}>{props.title ? props.title : "Report of selected areas"}</Text>
                  {report}
              </View>
              </Page>
            {/* <Image style={styles.image} fixed={true}
              debug={true} src={"deepforestry-logo.png"}></Image> */}
        </Document>
)};


ReportEditing.propTypes = {
    selectedBagFiles: PropTypes.array,
    forestData: PropTypes.array,
    title: PropTypes.string,
    ctx: PropTypes.object.isRequired
}